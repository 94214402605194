import React from "react"
import Layout from "../components/Layout"
import Title from "../components/Title"
import SEO from "../components/SEO"
import NizWidget from "../assets/WorkPictureWidgetsNizhoni.jpg"
import WAWidget from '../assets/WorkPictureWidgetsWA.jpg';
import { StaticImage } from "gatsby-plugin-image";
// ...GatsbyImageSharpFluid

const Portfolio = () => {
  return (
    <><SEO title="Our Work" />
    <Layout>
    <section>
      <div className="hero-containerSubPage">
        <div className="heroTextOverlay">
          <center>
            <h1 className="heroText section-padBotx5">View Our Recent Work</h1>
          </center>
        </div>
        <StaticImage
          alt="Our Services"
          className="backgroundImage"
          style={{position: "absolute"}}
          src="../assets/PortfolioHero.jpeg"
        />
      </div>
    </section>
      <div className="section-padTop">
        <Title title="Portfolio" />
      </div>
      <section className="section-padBot section-padTop">
        <div className='container section-padTopx2'>
          <div className='row'>
            <div className='col-md-6 text-left containerParent'>
              <h3 className="section-padTop textDirSwap">Nizhoni Properties LLC</h3>
              <p className="section-padTop textDirSwap">Nizhoni Properties LLC is a real estate agency in Catalina Arizona. They contacted us for a new website and logo. They wanted to integrate with the third party software they were using to manage buildings and tenants. We connected their buildium data to their website so they could market their homes & apartments more efficiently. The website offers a content managment system so they can easily edit their site. We also helped them with branding by creating them a new logo.</p>
            </div>
            <div className='col-md-6'>
              <img src={NizWidget} class="img-responsive" alt="Web and Development" />
            </div>
          </div>

          <div className='container section-padTopx3'>
            <div className='row'>
              <div className='col-md-6'>
                <img src={WAWidget} class="img-responsive" alt="Web and Development" />
              </div>
              <div className='col-md-6 text-left'>
                <h3 className="section-padTop textDirSwap">Westmoreland Aviation</h3>
                <p className="section-padTop textDirSwap">Westmoreland Aviation requested a new website to help boost SEO (Search Engine Optimization).By using a Static Site Generator with an open source Content Managment System we were not only able to help them get much better search results but we also cut some unnecessary Word Press costs.</p>
              </div>
            </div>
          </div>

        </div>
      </section>
    </Layout></>
  )
}


export default Portfolio
